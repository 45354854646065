import React, { createRef, useContext, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';

import { getToken } from "../../../../util/tokenOrganizer";
import {getProgramStudi} from '../../../../api/programStudiApi';
import { useParams } from 'react-router';
import { DetailFormContext } from './FormContext';

function FormValidation() {

    const [ctx, setCtx] = useContext(DetailFormContext);
    const [data, setData] = useState({});
    useEffect(() => {
        setFormData({
            ...formData,
            ['priority'] : {
                ...formData['priority'],
                value: ctx.priority
            },
            ['pertanyaan'] : {
                ...formData['pertanyaan'],
                value: ctx.pertanyaan
            },
            ['deskripsi'] : {
                ...formData['deskripsi'],
                value: ctx.deskripsi
            },
            ['nilai'] : {
                ...formData['nilai'],
                value: ctx.nilai
            },
            ['penalti'] : {
                ...formData['penalti'],
                value: ctx.penalti
            },
            ['jawaban'] : {
                ...formData['jawaban'],
                value: ctx.jawaban
            },
            ['tipe_soal'] : {
                ...formData['tipe_soal'],
                value: ctx.tipe_soal ?? 'pilihan_ganda'
            },
        })
    }, [ctx])

    const param = useParams();
    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const tipeSoalList = [
        {
            value: "pilihan_ganda",
            label: "Pilihan Ganda",
        },
        {
            value: "multi_opsi",
            label: "Multi Opsi",
        },
    ];

    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            // assesment/MDBlODZhODFkOGNhOWI1ZWUyMzlkYWNjZWRkMGZjMGU=/soal_assesment
            window.location.href = `/assesment/${param.slug}/soal_assesment`;
        }
    };

    const [formData, setFormData] = useState({
        priority: {
            label: 'Priority',
            value: data.priority ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        pertanyaan: {
            label: 'Pertanyaan',
            value: data.pertanyaan ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        deskripsi: {
            label: 'deskripsi',
            value: data.deskripsi ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        tipe_soal: {
            label: 'tipe_soal',
            value: data.tipe_soal ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        jawaban: {
            label: 'jawaban',
            value: data.jawaban ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nilai: {
            label: 'nilai',
            value: data.nilai ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        penalti: {
            label: 'penalti',
            value: data.penalti ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();

        const formFields = Object.keys(formData);
        let newFormData = { ...formData }
        let formSubmit = {};
        let check_error = 0;
        for (const element of formFields) {
            const currentField = element;
            const currentValue = formData[currentField].value;
            const currentLabel = formData[currentField].label;
            if (currentValue === '') {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: true,
                        errorMessage: currentLabel + ' kosong'
                    }
                }
                check_error++;
            } else {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: false
                    }
                }
                formSubmit[currentField] = currentValue;
            }

        }

        setFormData(newFormData)
        if (check_error === 0) {
            axios
                .post(`${configApi.BASE_URL_API}/data-assesment/${param.slug}/soal/${param.id}`, formSubmit, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.message)
                    setOpen(true)
                });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.pertanyaan.error}
                        id="outlined-pertanyaan-text"
                        label="Pertanyaan"
                        type='text'
                        name='pertanyaan'
                        onChange={handleChange}
                        value={formData.pertanyaan.value}
                        helperText={formData.pertanyaan.error ? formData.pertanyaan.errorMessage : ''}
                        multiline
                        maxRows={4}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.deskripsi.error}
                        id="outlined-deskripsi-text"
                        label="Deskripsi"
                        type='text'
                        name='deskripsi'
                        onChange={handleChange}
                        value={formData.deskripsi.value}
                        helperText={formData.deskripsi.error ? formData.deskripsi.errorMessage : ''}
                        multiline
                        maxRows={4}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        id="outlined-tipe_soal-option"
                        error={formData.tipe_soal.error}
                        select
                        label="Tipe Soal"
                        onChange={handleChange}
                        value={formData.tipe_soal.value}
                        name='tipe_soal'
                        helperText={formData.tipe_soal.error ? formData.tipe_soal.errorMessage : 'Silahkan pilih jurusan'}
                    >
                        {tipeSoalList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.nilai.error}
                        id="outlined-nilai-text"
                        label="Nilai"
                        type='number'
                        name='nilai'
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 1, step: 0.1 } }}
                        value={formData.nilai.value}
                        helperText={formData.nilai.error ? formData.nilai.errorMessage : ''}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.penalti.error}
                        id="outlined-penalti-text"
                        label="Penalti"
                        type='number'
                        name='penalti'
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 1, step: 0.1 } }}
                        value={formData.penalti.value}
                        helperText={formData.penalti.error ? formData.penalti.errorMessage : ''}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.jawaban.error}
                        id="outlined-jawaban-text"
                        label="Jawaban"
                        type='text'
                        name='jawaban'
                        onChange={handleChange}
                        value={formData.jawaban.value}
                        helperText={formData.jawaban.error ? formData.jawaban.errorMessage : ''}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField
                        error={formData.priority.error}
                        id="outlined-priority-text"
                        label="Priority Soal/Urutan Soal"
                        type='number'
                        name='priority'
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={handleChange}
                        value={formData.priority.value}
                        helperText={formData.priority.error ? formData.priority.errorMessage : ''}
                    />
                </FormControl>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    Update
                </Button>
            </ValidatorForm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FormValidation;