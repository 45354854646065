import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useParams } from 'react-router';
import { AssesmentDetailContext } from './AssesmentDetailContext';

const ucfirst = (text) => {
  return text.split("_").map((v) => {
    return v.charAt(0).toUpperCase() + v.slice(1)
  }).join(" ");
}

export default function AssesmentDetailBreadCrumb() {

  let param = useParams();
  let studi_kasus_param = ucfirst(param.studi_kasus);

  let ctx = React.useContext(AssesmentDetailContext)

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/monitoring">
        Monitoring Overview
      </Link>
      <Link underline="hover" color="inherit" href={"/monitoring/"+param.studi_kasus}>
        {studi_kasus_param}
      </Link>
      <Typography color="text.primary">{ctx.id} - {ctx.mahasiswa?.nama}</Typography>
    </Breadcrumbs>
  );
}