import React, { createRef, useContext, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';

import { getToken } from "../../../../util/tokenOrganizer";
import { getProgramStudi } from '../../../../api/programStudiApi';
import { useParams } from 'react-router';
import { DetailFormContext } from './FormContext';

function FormListOpsiJawabanDetail({ opsi }) {

  const [ctx, setCtx] = useContext(DetailFormContext);
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    setFormData({
      ...formData,
    })
  }, [ctx])

  const param = useParams();
  const [open, setOpen] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [message, setMessage] = React.useState('');


  const handleClose = () => {
    setOpen(false);
    if (isSuccess) {
      window.location.reload();
    }
  };

  const [formData, setFormData] = useState({
    key_opsi: {
      label: 'Opsi',
      value: data.key_opsi ?? opsi.key_opsi,
      error: false,
      errorMessage: 'Opsi is required'
    },
    id: {
      label: 'ID',
      value: data.id ?? opsi.id,
      error: false,
      errorMessage: 'ID is required'
    },
    label_opsi: {
      label: 'Label Teks',
      value: data.label_opsi ?? opsi.label_opsi,
      error: false,
      errorMessage: 'Label is required'
    },
  });

  const formRef = createRef();

  const handleSubmit = e => {
    e.preventDefault();
    const formFields = Object.keys(formData);
    console.log({formFields})
    let newFormData = { ...formData }
    let formSubmit = {};
    let check_error = 0;
    for (const element of formFields) {
      const currentField = element;
      const currentValue = formData[currentField].value;
      const currentLabel = formData[currentField].label;
      if (currentValue === '') {
        newFormData = {
          ...newFormData,
          [currentField]: {
            ...newFormData[currentField],
            error: true,
            errorMessage: currentLabel + ' kosong'
          }
        }
        check_error++;
      } else {
        newFormData = {
          ...newFormData,
          [currentField]: {
            ...newFormData[currentField],
            error: false
          }
        }
        formSubmit[currentField] = currentValue;
      }

    }

    setFormData(newFormData)
    if (check_error === 0) {
      if (isUpdate) {
        axios
        .put(`${configApi.BASE_URL_API}/data-assesment/${param.slug}/opsi/${param.id}`, formSubmit, {
          headers: {
            Authorization: "Bearer " + getToken()
          }
        })
        .then((response) => {
          setIsSuccess(true)
          setMessage(response.data.msg)
          setOpen(true)
        }).catch((err) => {
          setIsSuccess(false)
          setMessage(err.response.data.message)
          setOpen(true)
        });
        
      } else {
        axios
        .delete(`${configApi.BASE_URL_API}/data-assesment/${param.slug}/opsi/${param.id}`, {
          headers: {
            Authorization: "Bearer " + getToken()
          },
          data: {
            id: formSubmit['id']
          }
        })
        .then((response) => {
          setIsSuccess(true)
          setMessage(response.data.msg)
          setOpen(true)
        }).catch((err) => {
          setIsSuccess(false)
          setMessage(err.response.data.message)
          setOpen(true)
        });

      }
      
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value
      }
    })
  }

  return (
    <div className='my-2'>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div className='content-center gap-4 flex flex-row'>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <TextField
              error={formData.key_opsi.error}
              id="outlined-key_opsi-text"
              label="Opsi"
              type='text'
              name='key_opsi'
              onChange={handleChange}
              value={formData.key_opsi.value}
              helperText={formData.key_opsi.error ? formData.key_opsi.errorMessage : ''}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <TextField
              error={formData.label_opsi.error}
              id="outlined-label_opsi-text"
              label="Label Opsi"
              type='text'
              name='label_opsi'
              InputProps={{ inputProps: { min: 1 } }}
              onChange={handleChange}
              value={formData.label_opsi.value}
              helperText={formData.label_opsi.error ? formData.label_opsi.errorMessage : ''}
            />
          </FormControl>
          <div className='content-center grid'>
            <Button
              color="warning"
              variant="contained"
              size='small'
              type="submit"
              onClick={()=>{
                setIsUpdate(true)
              }}
              className='m-2'
            >
              Edit
            </Button>
          </div>
          <div className='content-center grid'>
            <Button
              color="error"
              variant="contained"
              size='small'
              type="submit"
            >
              Hapus
            </Button>
          </div>
        </div>
      </ValidatorForm>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-opsi-soal-detail-title"
        aria-describedby="alert-dialog-opsi-soal-detail"
      >
        <DialogTitle id="alert-dialog-opsi-soal-detail-title">
          {"Informasi"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-opsi-soal-detail">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormListOpsiJawabanDetail;
