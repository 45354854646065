import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

export default function ControlledCheckbox({
    element,
    idx,
    studiKasusList,
    setStudiKasusList
}) {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        let temp = studiKasusList;
        temp[idx].checked = event.target.checked;
        setStudiKasusList(temp);
    };

    React.useEffect(function () {
        setChecked(element.checked);
    }, []);

    return (
        <FormControlLabel control={<Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />} label={element.title} />
    );
}
