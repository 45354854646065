import React from 'react';
import MiniDrawer from '../../../components/Menu/Menu';
import AssesmentDetailContent from './AssesmentDetailContent';

function AssesmentDetail() {
    return (
        <MiniDrawer>
            <AssesmentDetailContent />
        </MiniDrawer>
    );
}

export default AssesmentDetail;