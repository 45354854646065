import React from 'react';
import MiniDrawer from './Menu';

function PageError() {
    return (
        <MiniDrawer>
            Sorry, seems look like we lost :(
        </MiniDrawer>
    );
}

export default PageError;