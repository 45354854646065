import React from 'react';
import MiniDrawer from '../../../components/Menu/Menu';
import MahasiswaContent from './MahasiswaContent';

function Mahasiswa() {
    return (
        <MiniDrawer>
            <MahasiswaContent />
        </MiniDrawer>
    );
}

export default Mahasiswa;