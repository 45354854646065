import SingleMenu from "./SingleMenu";
import InboxIcon from '@mui/icons-material/MoveToInbox';


function SingleMenuMenu({openMenu}) {
    return <SingleMenu
        text={"Menu"}
        icon={<InboxIcon />}
        openMenu={openMenu}
        path={"/menu"}
    />;
}

export default SingleMenuMenu;