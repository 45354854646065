import React from 'react';

import SingleMenu from '../Menu/SingleMenu';
import { List } from '@mui/material';
import DropdownMenu from '../Menu/DropdownMenu';
import {
    Assignment,
    Badge as BadgeIcon,
    Dashboard,
    Monitor,
    People,
    Person,
    Settings
} from '@mui/icons-material';

const listMenu = [
    {
        type: "single",
        text: "Dashboard",
        icon: <Dashboard />,
        path: "/dashboard",
        permissionRoles : [
            "admin",
            "dosen"
        ]
    },
    {
        type: "single",
        text: "Monitoring",
        icon: <Monitor />,
        path: "/monitoring",
        permissionRoles : [
            "admin",
            "dosen"
        ]
    },
    {
        type: "single",
        text: "Assesment",
        icon: <Assignment />,
        path: "/assesment",
        permissionRoles : [
            "admin",
            "dosen"
        ]
    },
    {
        type: "multi",
        text: "Master",
        icon: <Settings />,
        children: [
            { icon: <Assignment />, label: 'Bimbingan', path: "/bimbingan" },
            { icon: <Person />, label: 'Mahasiswa', path: "/master/mahasiswa" },
            { icon: <BadgeIcon />, label: 'Dosen', path: "/master/dosen" },
            { icon: <People />, label: 'User', path: "/master/user" },
            // { icon: <Article />, label: 'Studi Kasus', path: "/master/studi-kasus" },
        ],
        permissionRoles : [
            "admin"
        ]
    },
];

function Sidebar({open, profile}) {
    return (
        <List>
            {listMenu.map((menu, index)=> {
                if (menu.type == "single" && menu.permissionRoles.includes(profile.role)) {
                    return (<SingleMenu
                        key={menu.text}
                        openMenu={open}
                        text={menu.text}
                        icon={menu.icon}
                        path={menu.path}
                    />);
                } else if (menu.permissionRoles.includes(profile.role)) {
                    return (<DropdownMenu
                        key={menu.text}
                        openMenu={open}
                        text={menu.text}
                        icon={menu.icon}
                        data={menu.children}
                    />);
                }
            })}
        </List>
    );
}

export default Sidebar;