import * as React from 'react';
import AssesmentBreadCrumb from './AssesmentBreadCrumb';
import { Button, Card, Grid, Paper, Typography } from '@mui/material';
import BasicList from './list/BasicList';
import { useNavigate } from 'react-router';
import ListAltIcon from '@mui/icons-material/ListAlt';

export default function AssesmentContent() {

    const navigate = useNavigate();
    return (
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <AssesmentBreadCrumb />
            </div>

            <Grid container
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: '20px' }}
                alignItems="center">
                <Typography variant="h6" style={{ color: '#3d3d3d', fontWeight: 'medium' }}>Overview</Typography>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card variant="outlined" sx={{
                        marginBottom: '10px',
                    }}>
                        <BasicList kasus={'jantung_koroner'} kasus_title={'Jantung Koroner'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card variant="outlined" sx={{
                        marginBottom: '10px',
                    }}>
                        <BasicList kasus={'diabetes_mellitus'} kasus_title={'Diabetes Mellitus'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card variant="outlined" sx={{
                        marginBottom: '10px',
                    }}>
                        <BasicList kasus={'pneumonia'} kasus_title={'Pneumonia'} />
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}