import * as React from 'react';
import UserTable from './UserTable';
import UserBreadCrumb from './UserBreadCrumb';
import { Button, Grid } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

export default function UserContent() {
    return (
        <>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <div style={{ marginBottom: "20px" }}>
                    <UserBreadCrumb />
                    <br />
                    <Button
                        className='mt-2'
                        component="label"
                        variant="contained"
                        color="success"
                        onClick={() => {
                            window.location = "/master/user/form";
                        }}
                        startIcon={<AddBoxIcon />}>
                        Tambah
                    </Button>
                </div>
                <Grid item xs={12}>
                    <UserTable />
                </Grid>
            </Grid>
        </>
    );
}