import React, { useState } from 'react';
import UserDetailBreadCrumb from './UserDetailBreadCrumb';
import { Button, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { detailUser } from '../../../../api/userApi';
import UserFormValidation from './UserFormValidation';

function UserDetailContent() {
    let param = useParams();


    let [dataUser, setDataUser] = useState({})
    let [isLoading, setIsLoading] = useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailUser(param.slug);
        setIsLoading(false);
        setDataUser(res.data);
    };

    React.useEffect(() => {
        getData();
    }, [])

    return (

        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <UserDetailBreadCrumb dataUser={dataUser} />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        backgroundColor: "#707070",
                    }}
                    component={Link} to="/master/user"
                >
                    Kembali
                </Button>
            </Grid>
            <div className='pt-2 w-full'>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            {
                                isLoading ?
                                (<CircularProgress />) :
                                (<UserFormValidation dataUser={dataUser} />)
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </div>
        </Grid>
    );
}

export default UserDetailContent;