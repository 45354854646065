import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import { Stack } from '@mui/system';
import { Star, StarHalf } from '@mui/icons-material';

export default function AssesmentJawabanListSingle({
    data
}) {
    const v = data;

    let child = <></>;
    
    if (data?.child && data.child.length > 0) {
        child = data.child.map(child => <AssesmentJawabanListSingle key={child.id} data={child} />)

    }
    let opsi_arr = v.opsi.map(c => `${c.key_opsi}. ${c.label_opsi}`)
    let string_opsi = opsi_arr.join(" | ");

    let string_jawaban = '';
    let jawaban = [];
    if (v.jawaban != null) {
        jawaban = v.jawaban.split(",");
        string_jawaban = jawaban.join(", ");
    }
    let string_jawaban_mahasiswa = '-';
    let jawaban_mahasiswa = [];
    let salah = 0;
    if (v.jawaban_mahasiswa != null) {
        jawaban_mahasiswa = v.jawaban_mahasiswa.split(",");
        string_jawaban_mahasiswa = jawaban_mahasiswa.join(", ");

        if (jawaban_mahasiswa.length == 0) {
            salah++;
        }
    }
    let benar = 0;
    jawaban_mahasiswa.forEach(element => {
        if (jawaban.indexOf(element) > -1) {
            benar++;
        } else {
            salah++;
        }
    });
    if (jawaban_mahasiswa.length == 0) {
        salah++;
    }
    let star = <StarBorder />;
    if (benar == jawaban.length && jawaban.length > 0) {
        star = <Star />;
    } else if (benar != 0) {
        star = <StarHalf />;
    }
    let nilai = benar * v.nilai - salah * v.penalti;
    return (
        <>
            <ListItemButton>
                <ListItemIcon>
                    {star}
                </ListItemIcon>
                <ListItemText primary={
                    <Stack>
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>{v.pertanyaan}</Typography>
                        <Typography variant='body2'>{v.pertanyaan}</Typography>
                        <Typography variant='body2'>Opsi : {string_opsi}</Typography>
                        <Typography variant='body2'>Bobot Nilai</Typography>
                        <Typography variant='body2'>- Benar {v.nilai}</Typography>
                        <Typography variant='body2'>- Salah {v.penalti}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Kunci Jawaban : {string_jawaban}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Jawaban Mahasiswa : {string_jawaban_mahasiswa}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Nilai Akhir : {nilai}</Typography>
                    </Stack>
                } />
                {star}
            </ListItemButton>
            {child}
        </>)
}