import * as React from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Grid,
    Paper
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FormBreadCrumb from './FormBreadCrumb';
import { useParams } from 'react-router';
import { detailAssesment, detailSoalAssesment, soalAssesment } from '../../../../api/assesmentApi';
import { DetailFormContext, FormContext } from './FormContext';
import FormValidation from './FormValidation';
import FormAddSoalValidation from './FormAddSoalValidation';
import FormListOpsiJawaban from './FormListOpsiJawaban';
// import FormValidation from './FormValidation';

export default function FormContent() {
    let param = useParams();

    let [dataAssesment, setDataAssesment] = React.useState({})
    let [detailSoal, setDetailSoal] = React.useState({})
    let [listOpsiJawaban, setListOpsiJawaban] = React.useState([])
    let [isLoading, setIsLoading] = React.useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailAssesment(param.slug);
        setDataAssesment(res.data);
        let resAw = await detailSoalAssesment(param.slug, param.id);
        setDetailSoal(resAw.data);
        setListOpsiJawaban(resAw.data.assesment_opsi);
        setIsLoading(false);
    };

    const handleClose = () => {
        // setIsLoading(false)
    }

    React.useEffect(() => {
        getData();
    }, [])



    return (
        <FormContext.Provider value={dataAssesment}>
            <DetailFormContext.Provider value={[detailSoal, setDetailSoal]}>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div style={{ marginBottom: "20px" }}>
                        <FormBreadCrumb detailSoal={detailSoal} />
                    </div>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Soal"
                            />
                            <FormValidation />
                        </Card>
                        <Card sx={{ marginTop: '1rem' }}>
                            <CardHeader
                                title="Form Opsi Jawaban"
                            />
                            <FormAddSoalValidation />
                            <FormListOpsiJawaban listOpsiJawaban={listOpsiJawaban} />
                        </Card>
                    </Grid>
                </Grid>
            </DetailFormContext.Provider>
        </FormContext.Provider>
    );
}