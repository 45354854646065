import React from 'react';
import MiniDrawer from '../../../../components/Menu/Menu';
import UserDetailContent from './UserDetailContent';

function UserDetail() {
    return (
        <MiniDrawer>
            <UserDetailContent />
        </MiniDrawer>
    );
}

export default UserDetail;