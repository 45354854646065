import React from 'react';
import MahasiswaFormBreadCrumb from './MahasiswaFormBreadCrumb';
import { Button, Card, CardContent, Grid } from '@mui/material';
import MahasiswaFormValidation from './MahasiswaFormValidation';
import { Link } from 'react-router-dom';

function MahasiswaFormContent() {
    return (

        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <MahasiswaFormBreadCrumb />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">

                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        backgroundColor: "#707070",
                    }}
                    component={Link} to="/master/mahasiswa"
                >
                    Kembali
                </Button>
            </Grid>
            <div className='pt-2'>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <MahasiswaFormValidation />
                        </CardContent>
                    </Card>
                </Grid>
            </div>

        </Grid>
    );
}

export default MahasiswaFormContent;