import * as React from 'react';
import BimbinganTable from './BimbinganTable';
import BimbinganBreadCrumb from './BimbinganBreadcrumb';
import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import BimbinganSwitchScope from './BimbinganSwitchScope';
import BimbinganDetailForm from './BimbinganDetailForm';
import { detailMahasiswa } from '../../../api/mahasiswa';
import BimbinganDetailFormScope from './BimbinganDetailFormScope';



export default function BimbinganContent() {
    let param = useParams();

    let [dataMahasiswa, setDataMahasiswa] = React.useState({})
    let [isLoading, setIsLoading] = React.useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailMahasiswa(param.encryptedId);
        setIsLoading(false);
        setDataMahasiswa(res.data);
    };

    React.useEffect(() => {
        getData();
    }, [])

    return (
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <BimbinganBreadCrumb />
            </div>
            <Grid item xs={12} gap={4}>

                <Card variant="outlined" className='p-4'>
                    <Typography gutterBottom variant="h6" component="div">
                        Dosen Pembimbing
                    </Typography>
                    <hr />
                    <BimbinganDetailForm />
                </Card>
                <div className='mt-4'>
                    <Card variant="outlined" className='p-4'>
                        <Typography gutterBottom variant="h6" component="div">
                            Scope Studi Kasus
                        </Typography>
                        <hr />
                        <BimbinganDetailFormScope />
                    </Card>
                </div>
            </Grid>
        </Grid>
    );
}