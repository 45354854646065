import React from 'react';
import MiniDrawer from './../../components/Menu/Menu';
import BimbinganContent from './BimbinganContent';

function Bimbingan() {
    return (
        <MiniDrawer>
            <BimbinganContent />
        </MiniDrawer>
    );
}

export default Bimbingan;