import React, { useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { BarChart, LineChart } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { statistikAssesment, statistikPengguna } from './../../api/dashboardApi';

const data = [
    { value: 5, label: 'A' },
    { value: 10, label: 'B' },
    { value: 15, label: 'C' },
    { value: 20, label: 'D' },
];

const size = {
    //   width: 400,
    height: 300,
};

function DashboardContent() {

    const [loading, setLoading] = useState(false)
    const [dataChartPengguna, setDataChartPengguna] = useState([])
    const [dataChartAssesment, setDataChartAssesment] = useState({
        label: [''],
        value: [0],
    })

    // get data
    const getData = async () => {
        setLoading(true)
        const resAssesment = await statistikAssesment();
        setDataChartAssesment(resAssesment.data);
        let label = resAssesment.data.map((v) => (v.label))
        let value = resAssesment.data.map((v) => (v.value))
        setDataChartAssesment({
            label,
            value
        })
        const resPengguna = await statistikPengguna();
        setDataChartPengguna(resPengguna.data);
        setLoading(false)
    };

    useEffect(() => {
        getData();
    }, [])



    return (
        <Grid container spacing={2}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Statistik Assesment
                        </Typography>
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: dataChartAssesment.label,
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: dataChartAssesment.value,
                                },
                            ]}
                            height={300}
                        />
                    </CardContent>
                </Card >
            </Grid>
            {/* <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Chart 2
                        </Typography>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                            series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                            height={300}
                        />
                    </CardContent>
                </Card >
            </Grid> */}
            {/* <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Chart 3
                        </Typography>
                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            // width={500}
                            height={300}
                        />
                    </CardContent>
                </Card >
            </Grid> */}
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Statistik Pengguna
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    arcLabel: (item) => `${item.label} (${item.value})`,
                                    arcLabelMinAngle: 45,
                                    data: dataChartPengguna,
                                },
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontWeight: 'bold',
                                },
                            }}
                            {...size}
                        />
                    </CardContent>
                </Card >
            </Grid>

        </Grid>
    );
}

export default DashboardContent;