import React from 'react';
import MiniDrawer from '../../../../components/Menu/Menu';
import DosenDetailContent from './DosenDetailContent';

function DosenDetail() {
    return (
        <MiniDrawer>
            <DosenDetailContent />
        </MiniDrawer>
    );
}

export default DosenDetail;