import React, { createRef, useContext, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';

import { getToken } from "../../../../util/tokenOrganizer";
import {getProgramStudi} from '../../../../api/programStudiApi';
import { useParams } from 'react-router';
import { DetailFormContext } from './FormContext';
import FormListOpsiJawabanDetail from './FormListOpsiJawabanDetail';

function FormListOpsiJawaban({listOpsiJawaban}) {
    return (
        <>
          {listOpsiJawaban.map(opsi => (
            <>
                <Divider component="div" />
                <FormListOpsiJawabanDetail opsi={opsi} />
            </>
          ))}
        </>
    )
}

export default FormListOpsiJawaban;
