import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

export default function BimbinganBreadcrumb() {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/bimbingan">
          Bimbingan
        </Link>
        <Typography color="text.primary">Atur Pembimbing</Typography>
      </Breadcrumbs>
    </div>
  );
}
