import * as React from 'react';
import AssesmentDetailTable from './AssesmentDetailTable';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Paper
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AssesmentDetailBreadCrumb from './AssesmentDetailBreadCrumb';
import { useParams } from 'react-router';
import { detailAssesment, soalAssesment } from '../../../api/assesmentApi';
import AssesmentDetailDataGrid from './AssesmentDetailDataGrid';
import { AssesmentDetailContext, SoalAssesmentDetailContext } from './AssesmentDetailContext';
import SoalList from './SoalList';

export default function AssesmentDetailContent() {
    let param = useParams();

    let [dataAssesment, setDataAssesment] = React.useState({})
    let [soalDataAssesment, setSoalDataAssesment] = React.useState([])
    let [isLoading, setIsLoading] = React.useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailAssesment(param.slug);
        setDataAssesment(res.data);
        const resSoal = await soalAssesment(param.slug);
        setSoalDataAssesment(resSoal.data);
        setIsLoading(false);
    };

    const handleClose = () => {
        // setIsLoading(false)
    }

    React.useEffect(() => {
        getData();
    }, [])


    return (
        <AssesmentDetailContext.Provider value={dataAssesment}>
            <SoalAssesmentDetailContext.Provider value={soalDataAssesment}>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div style={{ marginBottom: "20px" }}>
                        <AssesmentDetailBreadCrumb />
                    </div>
                    {/* <Grid container justifyContent="space-between" sx={{ marginBottom: '20px' }}>
                        <div></div>
                        <Button
                            className='mt-2'
                            component="label"
                            variant="contained"
                            color="success"
                            onClick={() => {
                            window.location.href = "/assesment/" + param.slug + "/soal_assesment/form"
                            }}
                            startIcon={<AddBoxIcon />}>
                            Tambah
                        </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                        {/* <AssesmentDetailDataGrid /> */}
                        <SoalList />
                    </Grid>
                </Grid>
            </SoalAssesmentDetailContext.Provider>
        </AssesmentDetailContext.Provider>
    );
}