import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';

function SingleMenu({ icon, text, openMenu, path }) {
    return (<>
        <NavLink
            to={path}
            className={({ isActive, isPending, isTransitioning }) =>
                [
                    isPending ? "pending" : "",
                    isActive ? "active" : "",
                    isTransitioning ? "transitioning" : "",
                ].join(" ")
            }
        >
            {({ isActive, isPending, isTransitioning }) => (
                <ListItem key={text} disablePadding sx={{
                    display: 'block',
                    backgroundColor: isActive && '#EEEEEE'
                }}>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: openMenu ? 'initial' : 'center',
                            px: 2.5,
                            color: isActive && '#4E4FEB',
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openMenu ? 3 : 'auto',
                                justifyContent: 'center',
                                color: isActive && '#4E4FEB',
                            }}
                        >
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{fontWeight: isActive ? 700 : 400}}>{text}</Typography>}
                            sx={{ opacity: openMenu ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            )}
        </NavLink>
    </>);
}

export default SingleMenu;