import React from 'react';
import MiniDrawer from './Menu';
import Content from './Content';


function SamplePage() {
    return (
        <MiniDrawer>
            <Content></Content>
        </MiniDrawer>
    );
}

export default SamplePage;