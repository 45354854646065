import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export default function MahasiswaBreadCrumb() {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Typography color="text.primary">Data Mahasiswa</Typography>
    </Breadcrumbs>
  );
}
