import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { AssesmentJawabanContext } from './AssesmentDetailContext';
import AssesmentJawabanListSingle from './AssesmentJawabanListSingle';

export default function AssesmentJawaban() {

    let ctx = React.useContext(AssesmentJawabanContext)

    const entries = Object.entries(ctx);

    return (
        <Box sx={{ flexGrow: 1 }} style={{
            marginBottom: '10px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Jawaban Mahasiswa
                            </ListSubheader>
                        }
                    >
                        {entries.map(va => {
                            const v = ctx[va[0]];
                            return <AssesmentJawabanListSingle key={v.id} data={v}/>})}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}
