import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function MahasiswaDetailBreadCrumb(dataMahasiswa) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/master/mahasiswa">
                Data Mahasiswa
            </Link>
            <Typography color="text.primary">{dataMahasiswa.dataMahasiswa.nim} - {dataMahasiswa.dataMahasiswa.nama}</Typography>
        </Breadcrumbs>
    );
}
