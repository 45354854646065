import * as React from 'react';
import StudiKasusTable from './StudiKasusTable';
import StudiKasusBreadCrumb from './StudiKasusBreadCrumb';
import { Grid } from '@mui/material';

export default function StudiKasusContent() {
    return (
        <>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <div style={{marginBottom: "20px"}}>

                    <StudiKasusBreadCrumb />
                </div>
                <Grid item xs={12}>
                    <StudiKasusTable />
                </Grid>
            </Grid>
        </>
    );
}