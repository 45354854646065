import axios from "axios";
import { BASE_URL_API } from "../config/api";
import { getToken, revokeToken } from "../util/tokenOrganizer";


export const listStudiKasusLite = (query) => {
    query = {
        ...query
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/studi_kasus_lite`, {
                params: query,
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}