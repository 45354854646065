import * as React from 'react';
import DosenTable from './DosenTable';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DosenBreadCrumb from './DosenBreadCrumb';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

export default function DosenContent() {

    const navigate = useNavigate();
    return (
        <>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">

                <div style={{ marginBottom: "20px" }}>
                    <DosenBreadCrumb />
                    <br />
                    <Button
                        className='mt-2'
                        component="label"
                        variant="contained"
                        color="success"
                        onClick={() => {
                            navigate("/master/dosen/form")
                        }}
                        startIcon={<AddBoxIcon />}>
                        Tambah
                    </Button>
                </div>
                <Grid item xs={12}>
                    <DosenTable />
                </Grid>
            </Grid>
        </>
    );
}