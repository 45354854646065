import axios from "axios";
import { BASE_URL_API } from "../config/api";
import { getToken, revokeToken } from "../util/tokenOrganizer";

const getProfile = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/profile-dosen`, {
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data.data)
            }).catch((err) => {
                resolve({})
            });
    });
}

export default getProfile;