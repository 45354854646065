import React, { createRef, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';

import { getToken } from "../../../../util/tokenOrganizer";
import {getProgramStudiId} from '../../../../api/programStudiApi';

function UserFormValidation() {


    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const getProgramStudiApi = async () => {
        let res = await getProgramStudiId();
        console.log({res})
        setProgramStudiList(res)
    }

    useEffect(() => {
        getProgramStudiApi();
    }, []);


    const [programStudiList, setProgramStudiList] = useState([]);

    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            window.location.href = "/master/user";
        }
    };

    const [formData, setFormData] = useState({
        email: {
            label: 'Email',
            value: '',
            error: false,
            errorMessage: 'You must enter a mail'
        },
        password: {
            label: 'Password',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        password_confirm: {
            label: 'Password Confirm',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nidn: {
            label: 'NIDN',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nim: {
            label: 'NIM',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        fullname: {
            label: 'Full Name',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        alamat: {
            label: 'Alamat',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        jurusan_nama: {
            label: 'Jurusan',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        jurusan_id: {
            label: 'Jurusan',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        role: {
            label: 'Role',
            value: 'mahasiswa',
            error: false,
            errorMessage: 'You must enter a name'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();

        const formFields = Object.keys(formData);
        let newFormData = { ...formData }
        let formSubmit = {};
        let check_error = 0;
        for (const currentField of formFields) {
            const currentValue = formData[currentField].value;
            const currentLabel = formData[currentField].label;
            if (['nidn', 'nim', 'jurusan_nama', 'jurusan_id', 'alamat'].includes(currentField)) {
                continue;
            }
            if (currentValue === '') {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: true,
                        errorMessage: currentLabel + ' kosong'
                    }
                }
                check_error++;
            } else if ((currentField === 'password' || currentField === 'password_confirm')) {
                if (currentValue.length < 8) {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: true,
                            errorMessage: currentLabel + ' minimal 8 karakter'
                        },
                    }
                    check_error++;
                } else if (newFormData.password.value != newFormData.password_confirm.value) {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: true,
                            errorMessage: 'Password tidak sama'
                        },
                    }
                    check_error++;
                } else {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: false,
                            errorMessage: ''
                        },
                    }
                    // formSubmit.push({ [currentField]: currentValue })
                    formSubmit[currentField] = currentValue;
                }
            } else if (currentField === 'email' &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(currentValue)) {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: true,
                        errorMessage: currentLabel + ' tidak valid'
                    }
                }
                check_error++;
            } else {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: false
                    }
                }
                formSubmit[currentField] = currentValue;
            }

        }

        setFormData(newFormData)
        console.log({formSubmit})
        console.log({check_error})
        if (check_error === 0) {
            axios
                .post(`${configApi.BASE_URL_API}/user`, formSubmit, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.message)
                    setOpen(true)
                });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >

                <div className='grid grid-cols-4 gap-2 w-full mb-2'>

                    <FormControl variant="outlined">
                        <TextField
                            error={formData.fullname.error}
                            id="outlined-fullname-text"
                            label="Nama"
                            type='text'
                            name='fullname'
                            onChange={handleChange}
                            value={formData.fullname.value}
                            helperText={formData.fullname.error ? formData.fullname.errorMessage : ''}
                        />
                    </FormControl>

                    <FormControl
                        // sx={{ m: 1, width: '25%' }}
                        variant="outlined">
                        <TextField
                            error={formData.email.error}
                            id="outlined-error-helper-text"
                            label="Email"
                            type='email'
                            name='email'
                            onChange={handleChange}
                            value={formData.email.value}
                            helperText={formData.email.error ? formData.email.errorMessage : ''}
                        />
                    </FormControl>

                    <FormControl
                        // sx={{ m: 1, width: '25%' }}
                        variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            error={formData.password.error}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            name='password'
                            value={formData.password.value}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {!!formData.password.error && (
                            <FormHelperText error id="outlined-adornment-password-error">
                                {formData.password.errorMessage}
                            </FormHelperText>
                        )}
                    </FormControl>


                    <FormControl
                        // sx={{ m: 1, width: '25%' }}
                        variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password-confirmation">Password Confirm</InputLabel>
                        <OutlinedInput
                            error={formData.password_confirm.error}
                            id="outlined-adornment-password-confirmation"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            name='password_confirm'
                            value={formData.password_confirm.value}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password Confirm"
                        />
                        {!!formData.password_confirm.error && (
                            <FormHelperText error id="outlined-adornment-password-confirmation-error">
                                {formData.password_confirm.errorMessage}
                            </FormHelperText>
                        )}
                    </FormControl>

                    {/* <FormControl
                        // sx={{ m: 1, width: '25ch' }}
                        variant="outlined">
                        <TextField
                            error={formData.alamat.error}
                            id="outlined-alamat-text"
                            label="Alamat"
                            type='text'
                            name='alamat'
                            onChange={handleChange}
                            value={formData.alamat.value}
                            helperText={formData.alamat.error ? formData.alamat.errorMessage : ''}
                            multiline
                            maxRows={4}
                        />
                    </FormControl> */}

                    {/* <FormControl
                        // sx={{ m: 1, width: '25ch' }}
                        variant="outlined">
                        <TextField
                            id="outlined-jurusan_id-option"
                            error={formData.jurusan_id.error}
                            select
                            label="Jurusan"
                            onChange={handleChange}
                            value={formData.jurusan_id.value}
                            name='jurusan_id'
                            helperText={formData.jurusan_id.error ? formData.jurusan_id.errorMessage : 'Silahkan pilih jurusan'}
                        >
                            {programStudiList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl> */}


                    <FormControl
                        // sx={{ m: 1, width: '25ch' }}
                        variant="outlined">
                        <TextField
                            id="outlined-role-option"
                            error={formData.role.error}
                            select
                            label="Role"
                            onChange={handleChange}
                            value={formData.role.value}
                            name='role'
                            helperText={formData.role.error ? formData.role.errorMessage : ''}
                        >
                            <MenuItem key={'mahasiswa'} value={'mahasiswa'}>
                                Mahasiswa
                            </MenuItem>
                            <MenuItem key={'dosen'} value={'dosen'}>
                                Dosen
                            </MenuItem>
                            {/* <MenuItem key={'admin'} value={'admin'}>
                                Admin
                            </MenuItem> */}
                        </TextField>
                    </FormControl>
                    {/* {formData.role.value === 'mahasiswa' && (
                        <FormControl
                            // sx={{ m: 1, width: '25ch' }}
                            variant="outlined">
                            <TextField
                                error={formData.nim.error}
                                id="outlined-nim-text"
                                label="NIM"
                                type='number'
                                name='nim'
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 1 } }}
                                value={formData.nim.value}
                                helperText={formData.nim.error ? formData.nim.errorMessage : ''}
                            />
                        </FormControl>
                    )}

                    {formData.role.value === 'dosen' && (
                        <FormControl
                            // sx={{ m: 1, width: '25ch' }}
                            variant="outlined">
                            <TextField
                                error={formData.nidn.error}
                                id="outlined-nidn-text"
                                label="NIDN"
                                type='number'
                                name='nidn'
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 1 } }}
                                value={formData.nidn.value}
                                helperText={formData.nidn.error ? formData.nidn.errorMessage : ''}
                            />
                        </FormControl>
                    )} */}
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
            </ValidatorForm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UserFormValidation;