import React from 'react';
import MiniDrawer from '../../../components/Menu/Menu';
import DosenContent from './DosenContent';

function Dosen() {
    return (
        <MiniDrawer>
            <DosenContent />
        </MiniDrawer>
    );
}

export default Dosen;