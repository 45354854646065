import React, { useState } from 'react';
import DosenDetailBreadCrumb from './DosenDetailBreadCrumb';
import { Button, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { detailDosen } from '../../../../api/dosenApi';
import { useParams } from 'react-router';
import DosenFormValidationDetail from './DosenFormValidation';
import { Link } from 'react-router-dom';
import DosenFormValidationImage from './DosenFormValidationImage';

function DosenDetailContent() {
    let param = useParams();

    let [dataDosen, setDataDosen] = useState({})
    let [isLoading, setIsLoading] = useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailDosen(param.slug);
        setIsLoading(false);
        setDataDosen(res.data);
    };

    React.useEffect(() => {
        getData();
    }, [])

    return (

        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <DosenDetailBreadCrumb dataDosen={dataDosen} />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        backgroundColor: "#707070",
                    }}
                    component={Link} to="/master/dosen"
                >
                    Kembali
                </Button>
            </Grid>
            <Grid item xs={12} sx={{
                paddingTop: "8px"
            }}>
                <Card variant="outlined">
                    <CardContent>

                        <img src={dataDosen.imageUrl} style={{
                            height: '200px'
                        }}></img>
                        
                        {
                            isLoading ?
                            (<CircularProgress />) :
                            (<DosenFormValidationImage dataDosen={dataDosen} />)
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sx={{
                paddingTop: "8px"
            }}>
                <Card variant="outlined">
                    <CardContent>
                        {
                            isLoading ?
                            (<CircularProgress />) :
                            (<DosenFormValidationDetail dataDosen={dataDosen} />)
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default DosenDetailContent;