import React, { createRef, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, FormControl, TextField } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import {AssesmentDetailContext} from './AssesmentDetailContext';

import configApi from '../../../config/api';
import { getToken } from "../../../util/tokenOrganizer";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function AssesmentDetailHeader() {
    const context = useContext(AssesmentDetailContext);
    const formRef = createRef();
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        const formFields = Object.keys(formData);
        let formSubmit = {};
        let check_error = 0;
        for (const element of formFields) {
            const currentField = element;
            const currentValue = formData[currentField].value;
            if (currentValue != '') {
                formSubmit[currentField] = currentValue;
            } else {
                check_error++;
            }

        }

        if (check_error === 0) {
            axios
                .post(`${configApi.BASE_URL_API}/data-assesment/${context.encryptedId}`, formSubmit, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                    window.location.reload()
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.msg)
                    setOpen(true)
                });
        }
    }

    useEffect(()=>{
        if (context?.deskripsi) {
            setFormData({
                ...formData,
                ['deskripsi']: {
                    ...formData['deskripsi'],
                    value: context.deskripsi
                }
            })
        }
    }, [context]);



    const [formData, setFormData] = useState({
        deskripsi: {
            label: 'Deskripsi',
            value: '',
            error: false,
            errorMessage: 'You must enter a mail'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
    }, [])

    return (
        <Box sx={{ flexGrow: 1 }} style={{
            marginBottom: '10px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <ValidatorForm
                            ref={formRef}
                            onSubmit={handleSubmit}
                        >
                            <FormControl sx={{ m: 1, }} fullWidth variant="outlined">
                                <TextField
                                    error={false}
                                    id="outlined-deskripsi-text"
                                    label="Deskripsi"
                                    type='text'
                                    name='deskripsi'
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    value={formData.deskripsi.value ?? ''}
                                />
                            </FormControl>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    p: 1,
                                    m: 1,
                                    bgcolor: 'background.paper',
                                    borderRadius: 1,
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={context.deskripsi == formData.deskripsi.value}
                                >
                                    {
                                        "Update"
                                    }
                                </Button>
                            </Box>
                        </ValidatorForm>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}