import React from 'react';
import MiniDrawer from '../../../../components/Menu/Menu';
import DosenFormContent from './DosenFormContent';

function DosenForm() {
    return (
        <MiniDrawer>
            <DosenFormContent />
        </MiniDrawer>
    );
}

export default DosenForm;