import React, { useState } from 'react';
import MahasiswaDetailBreadCrumb from './MahasiswaDetailBreadCrumb';
import { Button, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import MahasiswaFormValidation from './MahasiswaFormValidation';
import { detailMahasiswa } from '../../../../api/mahasiswa';
import { useParams } from 'react-router';
import MahasiswaFormValidationDetail from './MahasiswaFormValidation';
import { Link } from 'react-router-dom';
import MahasiswaFormValidationImage from './MahasiswaFormValidationImage';

function MahasiswaDetailContent() {
    let param = useParams();

    let [dataMahasiswa, setDataMahasiswa] = useState({})
    let [isLoading, setIsLoading] = useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailMahasiswa(param.slug);
        setIsLoading(false);
        setDataMahasiswa(res.data);
    };

    React.useEffect(() => {
        getData();
    }, [])

    return (

        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <MahasiswaDetailBreadCrumb dataMahasiswa={dataMahasiswa} />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        backgroundColor: "#707070",
                    }}
                    component={Link} to="/master/mahasiswa"
                >
                    Kembali
                </Button>
            </Grid>

            <Grid item xs={12} sx={{
                paddingTop: "8px"
            }}>
                <Card variant="outlined">
                    <CardContent>
                        <img src={dataMahasiswa.imageUrl} style={{
                            height: '200px'
                        }}></img>
                        {isLoading && (<CircularProgress />)}
                        {!isLoading && (<MahasiswaFormValidationImage dataMahasiswa={dataMahasiswa} />)}
                    </CardContent>
                </Card>
            </Grid>
                <Grid item xs={12} sx={{
                paddingTop: "8px"
            }}>
                    <Card variant="outlined">
                        <CardContent>
                            {isLoading && (<CircularProgress />)}
                            {!isLoading && (<MahasiswaFormValidationDetail dataMahasiswa={dataMahasiswa} />)}
                        </CardContent>
                    </Card>
                </Grid>
        </Grid>
    );
}

export default MahasiswaDetailContent;