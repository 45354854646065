import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';

function DropdownMenu({ openMenu, data, icon, text }) {

    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [parentActive, setParentActive] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        let result = data.filter(function (v, i) {
            return location.pathname.match(v.path);
        });

        setParentActive(result.length > 0);
        setOpenDrawer(result.length > 0);
        // Google Analytics
        // ga('send', 'pageview');
    }, [location, data]);

    return (<>

        <ListItemButton
            onClick={() => setOpenDrawer(!openDrawer)}
            sx={{
                minHeight: 48,
                justifyContent: openMenu ? 'initial' : 'center',
                px: 2.5,
                color: parentActive && '#4E4FEB',
                backgroundColor: parentActive && '#EEEEEE'
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: openMenu ? 3 : 'auto',
                    justifyContent: 'center',
                    color: parentActive && '#4E4FEB'
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={<Typography sx={{ fontWeight: parentActive ? 700 : 400 }}>{text}</Typography>}
                sx={{
                    opacity: openMenu ? 1 : 0,
                    fontWeight: parentActive && 'bold'
                }}
            />
            {openMenu && (
                <KeyboardArrowDown
                    sx={{
                        mr: -1,
                        opacity: 1,
                        transform: openDrawer ? 'rotate(-180deg)' : 'rotate(0)',
                        transition: '0.2s',
                    }}
                />)}
        </ListItemButton>
        {openDrawer &&
            data.map((item) => (
                <NavLink
                    key={item.label}
                    to={item.path}
                    className={({ isActive, isPending, isTransitioning }) =>
                        [
                            isPending ? "pending" : "",
                            isActive ? "active" : "",
                            isTransitioning ? "transitioning" : "",
                        ].join(" ")
                    }
                >
                    {({ isActive, isPending, isTransitioning }) => (
                        <ListItemButton
                            key={item.label}
                            sx={{
                                py: 0,
                                minHeight: 32,
                                justifyContent: openMenu ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive ? '#4E4FEB' : 'rgba(0,0,0,.8)',
                                backgroundColor: isActive && '#EEEEEE'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: openMenu ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'inherit',
                                    fontWeight: isActive ? 700 : 400
                                }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontWeight: isActive ? 700 : 400 }}>{item.label}</Typography>}
                                sx={{ opacity: openMenu ? 1 : 0 }}
                            />
                        </ListItemButton>
                    )}
                </NavLink>
            ))}

    </>);
}

export default DropdownMenu;