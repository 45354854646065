import * as React from 'react';
import BimbinganTable from './BimbinganTable';
import BimbinganBreadCrumb from './BimbinganBreadcrumb';
import { Card, Grid } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BimbinganTableUpdate from './BimbinganTableUpdate';

export default function BimbinganContent() {

    const navigate = useNavigate();

    return (
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <BimbinganBreadCrumb />
                {/* <div className='mt-4'>
                    <Button
                        component="label"
                        variant="contained"
                        color="success"
                        onClick={() => {
                            navigate("/bimbingan/form")
                        }}
                        startIcon={<AddBoxIcon />}>
                        Tambah
                    </Button>
                </div> */}
            </div>
            <Grid item xs={12}>

                <Card variant="outlined">

                </Card>
                <BimbinganTableUpdate />
            </Grid>
        </Grid>
    );
}