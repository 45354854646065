import axios from "axios";
import { BASE_URL_API } from "../config/api";
import { getToken, revokeToken } from "../util/tokenOrganizer";

export const listMonitoring = (param) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/monitoring/`+param, {
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const tableMonitoring = (param, query) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/monitoring/${param}/table`, {
                params: query,
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const detailMonitoring = (param, encryptedId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/monitoring/${param}/${encryptedId}`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

