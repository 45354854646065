import * as React from 'react';
import AssesmentTable from './AssesmentTable';
import AssesmentBreadCrumb from './AssesmentBreadCrumb';
import { Box, Button, Card, Grid, Paper } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

export default function AssesmentContent() {
    return (
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <AssesmentBreadCrumb />
            </div>
            <Grid item xs={12}>

                <Card variant="outlined">

                </Card>
                <AssesmentTable />
            </Grid>
        </Grid>
    );
}