import React from 'react';
import MiniDrawer from '../../../components/Menu/Menu';
import UserContent from './UserContent';

function User() {
    return (
        <MiniDrawer>
            <UserContent />
        </MiniDrawer>
    );
}

export default User;