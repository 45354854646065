import React from 'react';
import MiniDrawer from '../../components/Menu/Menu';
import DashboardContent from './DashboardContent';

function Dashboard() {
    return (
        <MiniDrawer>
            <DashboardContent />
        </MiniDrawer>
    );
}

export default Dashboard;