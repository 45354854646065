import React, { useEffect } from "react";

import {
    Route,
    Routes,
    Navigate,
    useLocation,
    useNavigate,
    Link,
    useParams,
} from "react-router-dom";

// context
import {
    useUserDispatch,
    useUserState,
    // forceLogin,
    forceLogout
} from "../context/UserContext";

// pages
import ListMenu from "./ListMenu/ListMenu";
import SamplePage from "./Menu/Page";
import PageError from "./Menu/PageError";
import Dashboard from "../pages/dashboard/Dashboard";
import StudiKasus from "../pages/master/StudiKasus/StudiKasus";
import SignIn from "../pages/login";
// dosen
import Dosen from "../pages/master/Dosen/Dosen";
import DosenForm from "../pages/master/Dosen/Form/DosenForm";
import DosenDetail from "../pages/master/Dosen/Detail/DosenDetail";
// mahasiswa
import Mahasiswa from "../pages/master/Mahasiswa/Mahasiswa";
import MahasiswaForm from "../pages/master/Mahasiswa/Form/MahasiswaForm";
import MahasiswaDetail from "../pages/master/Mahasiswa/Detail/MahasiswaDetail";
// user
import User from "../pages/master/User/User";
import UserForm from "../pages/master/User/Form/UserForm";
import UserDetail from "../pages/master/User/Detail/UserDetail";
// monitoring
import Monitoring from "../pages/monitoring/Assesment";
import MonitoringDetail from "../pages/monitoring/detail/AssesmentDetail";
import MonitoringDetailJawaban from "../pages/monitoring/detail_jawaban/AssesmentDetail";
// assesment
import Assesment from "../pages/assesment/Assesment";
import AssesmentDetail from "../pages/assesment/detail/AssesmentDetail";
import AssesmentSoal from "../pages/assesment/soal/AssesmentDetail";
import AssesmentSoalForm from "../pages/assesment/soal/form/Form";
import AssesmentSoalEdit from "../pages/assesment/soal/edit/Form";
import AssesmentDataPasien from "../pages/assesment/data_pasien/AssesmentDetail";
import AssesmentRekamMedik from "../pages/assesment/rekam_medik/AssesmentDetail";
import AssesmentDataKlinis from "../pages/assesment/data_klinis/AssesmentDetail";
import AssesmentDataLaboratorium from "../pages/assesment/data_laboratorium/AssesmentDetail";
import AssesmentDataTerapi from "../pages/assesment/data_terapi/AssesmentDetail";
import AssesmentTandaVital from "../pages/assesment/tanda_vital/AssesmentDetail";
import Bimbingan from "../pages/bimbingan/Bimbingan";
import BimbinganDetail from "../pages/bimbingan/detail/Bimbingan";

function UserLa() {
    let { slug } = useParams();
    return <><div>Protected {slug}</div>
        <Link to="/logout">{'logout'}</Link>
    </>;
}

const RequireAuth = ({ children }) => {

    let { isAuthenticated } = useUserState();
    let location = useLocation();
    if (!isAuthenticated) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}

const tokenOrganizer = require('./../util/tokenOrganizer');

const LogoutPage = ({ userDispatch, navigate }) => {
    tokenOrganizer.revokeToken();
    
    useEffect(() => {
        forceLogout(userDispatch, navigate);
    }, []);

    return <Navigate to="/protected" replace />;
}

export default function App() {
    // global
    useEffect(() => {
        document.title = 'SinalaCare';
    }, []);
    const userDispatch = useUserDispatch();
    const navigate = useNavigate();


    let token = tokenOrganizer.getToken();
    if (!token) {
        return <SignIn setToken={tokenOrganizer.setToken} />;
    }

    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/login" element={<SignIn />} /> */}
            <Route path="/menu" element={<SamplePage />} />
            <Route path="/hello" element={<SamplePage />} />
            <Route path="/auth" element={<SamplePage />} />
            <Route path="/database" element={<SamplePage />} />

            <Route path="assesment">
                <Route index element={<Assesment />} />
                <Route path=":slug" element={<AssesmentDetail />} />
                <Route path=":slug/soal_assesment" element={<AssesmentSoal />} />
                {/* <Route path=":slug/soal_assesment/form" element={<AssesmentSoalForm />} /> */}
                <Route path=":slug/soal_assesment/edit/:id" element={<AssesmentSoalEdit />} />
                <Route path=":slug/data_pasien" element={<AssesmentDataPasien />} />
                <Route path=":slug/rekam_medik" element={<AssesmentRekamMedik />} />
                <Route path=":slug/tanda_vital" element={<AssesmentTandaVital />} />
                <Route path=":slug/data_klinis" element={<AssesmentDataKlinis />} />
                <Route path=":slug/data_laboratorium" element={<AssesmentDataLaboratorium />} />
                <Route path=":slug/data_terapi" element={<AssesmentDataTerapi />} />
            </Route>
            <Route path="monitoring">
                <Route index element={<Monitoring />} />
                <Route path=":studi_kasus" element={<MonitoringDetail />} />
                <Route path=":studi_kasus/:encryptedId" element={<MonitoringDetailJawaban />} />
            </Route>
            <Route path="bimbingan">
                <Route index element={<Bimbingan />} />
                <Route path=":encryptedId" element={<BimbinganDetail />} />
                <Route path=":studi_kasus/:encryptedId" element={<MonitoringDetailJawaban />} />
            </Route>
            <Route path="master">
                <Route index element={<Navigate to={"/master/mahasiswa"} />} />
                <Route path="mahasiswa">
                    <Route index element={<Mahasiswa />} />
                    <Route path="form" element={<MahasiswaForm />} />
                    <Route path="edit/:slug" element={<MahasiswaDetail />} />
                    <Route path=":slug" element={<MahasiswaDetail />} />
                </Route>
                <Route path="dosen">
                    <Route index element={<Dosen />} />
                    <Route path="form" element={<DosenForm />} />
                    <Route path="edit/:slug" element={<DosenDetail />} />
                    <Route path=":slug" element={<DosenDetail />} />
                </Route>
                <Route path="user">
                    <Route index element={<User />} />
                    <Route path="form" element={<UserForm />} />
                    <Route path="edit/:slug" element={<UserDetail />} />
                    <Route path=":slug" element={<DosenDetail />} />
                </Route>
                <Route path="studi-kasus">
                    <Route index element={<StudiKasus />} />
                    <Route path=":slug" element={<SamplePage />} />
                </Route>
            </Route>
            <Route path="/list-menu" element={<ListMenu />} />
            <Route path="/logout" element={<LogoutPage navigate={navigate} userDispatch={userDispatch} />} />
            <Route
                path="/protected"
                element={
                    <RequireAuth>
                        <div>Protected</div>
                        <Link to="/logout">{'logout'}</Link>
                    </RequireAuth>
                }
            />
            <Route path="user">
                <Route index element={<><div>Protected User</div>
                    <Link to="/logout">{'logout'}</Link>
                </>} />
                <Route path="profile" element={<><div>Protected profile</div>
                    <Link to="/logout">{'logout'}</Link>
                </>} />
                <Route path=":slug" element={<UserLa />} />
            </Route>

            {/* <Route path='*' element={<ErrorBoundary />} /> */}
            <Route path='*' element={<PageError />} />
        </Routes>
    );

    // #######################################################################
}
