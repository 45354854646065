import axios from "axios";
import { BASE_URL_API } from "../config/api";
import { getToken, revokeToken } from "../util/tokenOrganizer";



export const statistikPengguna = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/dashboard/statistik_pengguna`, {
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const statistikAssesment = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/dashboard/statistik_assesment`, {
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}
