import React from 'react';
import MiniDrawer from '../../../../components/Menu/Menu';
import FormContent from './FormContent';

function Form() {
    return (
        <MiniDrawer>
            <FormContent />
        </MiniDrawer>
    );
}

export default Form;