import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { AssesmentDetailContext } from './AssesmentDetailContext';
import { useParams } from 'react-router';

export default function AssesmentDetailBreadCrumb() {

  const context = React.useContext(AssesmentDetailContext);
  const param = useParams();
  
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/assesment">
        Data Assesment
      </Link>
      <Link underline="hover" color="inherit" href={"/assesment/"+param.slug}>
        {context?.studi_kasus?.nama}
      </Link>
      <Typography color="text.primary">Soal Assesment</Typography>
    </Breadcrumbs>
  );
}