import React, { createRef, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../config/api';

import { getToken } from "../../../util/tokenOrganizer";
import { getProgramStudi } from '../../../api/programStudiApi';
import { Box } from '@mui/system';
import { listStudiKasusLite } from '../../../api/studiKasusApi';
import ControlledCheckbox from './ControlledCheckbox';
import { detailMahasiswa } from '../../../api/mahasiswa';
import { useParams } from 'react-router';

function BimbinganDetailFormScope() {


    let param = useParams();

    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [mahasiswa, setMahasiswa] = useState({});
    const [mahasiswaKasus, setMahasiswaKasus] = useState([]);
    const [programStudiList, setProgramStudiList] = useState([]);
    const [studiKasusList, setStudiKasusList] = useState([]);

    const getProgramStudiApi = async () => {
        const resMahasiswa = await detailMahasiswa(param.encryptedId);
        setMahasiswa(resMahasiswa.data)
        setMahasiswaKasus(resMahasiswa.data.mahasiswa_kasus)

        let resStudi = await listStudiKasusLite();

        let temp = resStudi.data.map((studiKasus) => {
            let check = resMahasiswa.data.mahasiswa_kasus.find(value => {
                return value.studi_kasus_id == studiKasus.idInt
            })
            return {
                ...studiKasus,
                checked: check != undefined
            };
        })
        setStudiKasusList(temp);
        let res = await getProgramStudi();
        setProgramStudiList(res)
    }

    useEffect(() => {
        getProgramStudiApi();
    }, []);

    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            window.location.href = "/bimbingan/" + param.encryptedId;
        }
    };

    const [formData, setFormData] = useState({
        studi_kasus: {
            label: 'Studi Kasus',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();

        let studi_kasus = [];
        studiKasusList.forEach(element => {
            if (element.checked) {
                studi_kasus.push(element.idInt)
            }
        });

        if (studi_kasus.length > 0) {
            axios
                .post(`${configApi.BASE_URL_API}/mahasiswa/${param.encryptedId}/scope-studi-kasus`, {
                    studi_kasus: studi_kasus
                }, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.message)
                    setOpen(true)
                });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    {studiKasusList.map((el, idx) => {
                        return (<Grid item xs={4} key={el.id}>
                            <ControlledCheckbox
                                element={el}
                                idx={idx}
                                studiKasusList={studiKasusList}
                                setStudiKasusList={setStudiKasusList}
                            />
                        </Grid>)
                    }
                    )}
                </Grid>
                <div className='mt-4'>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={submitted}
                    >
                        {
                            (submitted && 'Your form is submitted!')
                            || (!submitted && 'Submit')
                        }
                    </Button>
                </div>
            </ValidatorForm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default BimbinganDetailFormScope;