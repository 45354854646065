import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FormContext } from './FormContext';
import { useParams } from 'react-router';

export default function FormBreadCrumb({detailSoal}) {

  const context = React.useContext(FormContext);
  const param = useParams();
  
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/assesment">
        Data Assesment
      </Link>
      <Link underline="hover" color="inherit" href={"/assesment/"+param.slug}>
        {context?.studi_kasus?.nama}
      </Link>
      <Link underline="hover" color="inherit" href={"/assesment/"+param.slug+"/soal_assesment"}>
        Soal Assesment
      </Link>
      <Typography color="text.primary">{detailSoal.priority} - Edit</Typography>
    </Breadcrumbs>
  );
}