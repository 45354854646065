import axios from "axios";
import { BASE_URL_API } from "../config/api";
import { getToken, revokeToken } from "../util/tokenOrganizer";

export const listDosen = (query) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/dosen`, {
                params: query,
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const detailDosen = (encryptedId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_API}/dosen/${encryptedId}`, {
                headers: {
                    Authorization: "Bearer " + getToken()
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const storeDosen = (formData) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL_API}/dosen`, formData, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const updateDosen = (encryptedId, formData) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL_API}/dosen/${encryptedId}`, formData, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                resolve({})
            });
    });
}

export const deleteDosen = (encryptedId) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${BASE_URL_API}/dosen/${encryptedId}`, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                }
            })
            .then((response) => {
                resolve(response.data)
            }).catch((err) => {
                if(err.response.status == 403) {
                    revokeToken();
                }
                if (err.response) {
                    console.log('Error', err.response)
                    resolve(err.response.data.msg)
                } else if (err.request) {
                    // The request was made but no response was received
                    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(err.request);
                    resolve(err.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', err.message);
                    resolve(err.message)
                }
                console.log(err.config);
            });
    });
}
