import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function UserDetailBreadCrumb({dataUser}) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/master/user">
                Data User
            </Link>
            <Typography color="text.primary">{dataUser.fullname}</Typography>
        </Breadcrumbs>
    );
}
