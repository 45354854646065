import React, { createRef, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';
import {getProgramStudi} from '../../../../api/programStudiApi';
import { getToken } from "../../../../util/tokenOrganizer";

function DosenFormValidation({ dataDosen }) {
    // let dataDosen = props.dataDosen;

    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const getProgramStudiApi = async () => {
        let res = await getProgramStudi();
        setProgramStudiList(res)
    }

    useEffect(() => {
        getProgramStudiApi();
    }, []);


    const [programStudiList, setProgramStudiList] = useState([]);


    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            window.location.href = "/master/dosen/" + dataDosen.encryptedId;
        }
    };


    const [formData, setFormData] = useState({
        email: {
            label: 'Email',
            value: dataDosen.users?.email ?? '',
            error: false,
            errorMessage: 'You must enter a mail'
        },
        password: {
            label: 'Password',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        password_confirm: {
            label: 'Password Confirm',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nidn: {
            label: 'nidn',
            value: dataDosen.nidn ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nama: {
            label: 'Nama',
            value: dataDosen.nama ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        alamat: {
            label: 'Alamat',
            value: dataDosen.alamat ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        jurusan_nama: {
            label: 'Jurusan',
            value: dataDosen.jurusan_nama ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();
        const formFields = Object.keys(formData);
        let newFormData = { ...formData }
        let formSubmit = {};
        let check_error = 0;
        for (const element of formFields) {
            const currentField = element;
            const currentValue = formData[currentField].value;
            const currentLabel = formData[currentField].label;
            if (currentValue != '') {
                formSubmit[currentField] = currentValue;
            }

        }

        setFormData(newFormData)
        if (check_error === 0) {
            axios
                .post(`${configApi.BASE_URL_API}/dosen/${dataDosen.encryptedId}`, formSubmit, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.msg)
                    setOpen(true)
                });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
        >
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    error={formData.email.error}
                    id="outlined-error-helper-text"
                    label="Email"
                    type='email'
                    name='email'
                    onChange={handleChange}
                    value={formData.email.value}
                    helperText={formData.email.error ? formData.email.errorMessage : ''}
                />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    error={formData.password.error}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    name='password'
                    value={formData.password.value}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
                {!!formData.password.error && (
                    <FormHelperText error id="outlined-adornment-password-error">
                        {formData.password.errorMessage}
                    </FormHelperText>
                )}
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password-confirmation">Password Confirm</InputLabel>
                <OutlinedInput
                    error={formData.password_confirm.error}
                    id="outlined-adornment-password-confirmation"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    name='password_confirm'
                    value={formData.password_confirm.value}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password Confirm"
                />
                {!!formData.password_confirm.error && (
                    <FormHelperText error id="outlined-adornment-password-confirmation-error">
                        {formData.password_confirm.errorMessage}
                    </FormHelperText>
                )}
            </FormControl>


            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    error={formData.nidn.error}
                    id="outlined-nidn-text"
                    label="nidn"
                    type='number'
                    name='nidn'
                    onChange={handleChange}
                    InputProps={{ inputProps: { min: 1 } }}
                    value={formData.nidn.value}
                    helperText={formData.nidn.error ? formData.nidn.errorMessage : ''}
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    error={formData.nama.error}
                    id="outlined-nama-text"
                    label="Nama"
                    type='text'
                    name='nama'
                    onChange={handleChange}
                    value={formData.nama.value}
                    helperText={formData.nama.error ? formData.nama.errorMessage : ''}
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    error={formData.alamat.error}
                    id="outlined-alamat-text"
                    label="Alamat"
                    type='text'
                    name='alamat'
                    onChange={handleChange}
                    value={formData.alamat.value}
                    helperText={formData.alamat.error ? formData.alamat.errorMessage : ''}
                    multiline
                    maxRows={4}
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                    id="outlined-jurusan_nama-option"
                    error={formData.jurusan_nama.error}
                    select
                    label="Jurusan"
                    onChange={handleChange}
                    value={formData.jurusan_nama.value}
                    name='jurusan_nama'
                    helperText={formData.jurusan_nama.error ? formData.jurusan_nama.errorMessage : 'Silahkan pilih jurusan'}
                >
                    {programStudiList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>

            <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitted}
            >
                {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                }
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </ValidatorForm>
    );
}

export default DosenFormValidation;