import * as React from 'react';
import AssesmentDetailTable from './AssesmentDetailTable';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Paper
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AssesmentDetailBreadCrumb from './AssesmentDetailBreadCrumb';
import { useParams } from 'react-router';
import { detailAssesment } from '../../../api/assesmentApi';
import AssesmentDetailHeader from './AssesmentDetailHeader';
import AssesmentDetailDataGrid from './AssesmentDetailDataGrid';
import {AssesmentDetailContext} from './AssesmentDetailContext';

export default function AssesmentDetailContent() {
    let param = useParams();

    let [dataAssesment, setDataAssesment] = React.useState({})
    let [isLoading, setIsLoading] = React.useState(false)

    // get data
    const getData = async () => {
        // setIsLoading(true);
        // const res = await detailAssesment(param.slug);
        // setDataAssesment(res.data);
        // setIsLoading(false);
    };

    const handleClose = () => {
        // setIsLoading(false)
    }

    React.useEffect(() => {
        getData();
    }, [])

    // let assesmentContext = {
    //     dataAssesment,
    //     setDataAssesment
    // }

    return (
        <AssesmentDetailContext.Provider value={dataAssesment}>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div style={{ marginBottom: "20px" }}>
                    <AssesmentDetailBreadCrumb />
                </div>
                <Grid item xs={12}>
                    {/* <AssesmentDetailHeader /> */}
                    {/* <AssesmentDetailDataGrid dataAssesment={dataAssesment} /> */}
                    <AssesmentDetailTable />
                </Grid>
            </Grid>
        </AssesmentDetailContext.Provider>
    );
}