import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Card, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { AssesmentDetailContext, SoalAssesmentDetailContext } from './AssesmentDetailContext';
import SoalListSingle from './SoalListSingle';

export default function SoalList() {

    let ctx = React.useContext(SoalAssesmentDetailContext)
    let ctxDetail = React.useContext(AssesmentDetailContext)
    
    return (
        <Box sx={{ flexGrow: 1 }} style={{
            marginBottom: '10px'
        }}>
            <Card>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Soal Assesment {ctxDetail?.studi_kasus?.nama}
                                </ListSubheader>
                            }
                        >
                            {ctx.map(v => {
                                return <SoalListSingle key={v.id} data={v} />;
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}
