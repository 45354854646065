import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import { orange } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { listAssesment, deleteAssesment } from './../../api/assesmentApi';

import AlertDialog from './../../components/Common/AlertDialog'

export default function AssesmentTable() {

  const [stateTable, setStateTable] = useState({
    page: 0,
    count: 1,
    data: [["Loading Data..."]],
    isLoading: false
  });

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const handleClickOpen = (e, encryptedId) => {
    setSelectedId(encryptedId);
    setOpen(true);
  };

  const handleDelete = async () => {
    if (selectedId != '') {

      const res = await deleteAssesment(selectedId);
      setMessageAlert(res.msg)
      setOpenAlert(true)
      // window.location.reload();
    } else {
      alert('Tidak ada data dipilih')
    }
  };

  useEffect(() => {
    if (messageAlert != '' && !openAlert) {
      window.location.reload();
    }
  }, [openAlert]);

  const handleClose = () => {
    setSelectedId('');
    setOpen(false);
  };


  const columns = [
    {
      name: "studi_kasus",
      label: "Kasus",
      options: {
        filter: true,
        sort: true,
        customBodyRender:(value, tableMeta, updateValue) =>  {
            let studi_kasus = tableMeta.tableData[tableMeta.rowIndex][0];
            return (studi_kasus.nama)
        }
      }
    },
    {
      name: "deskripsi",
      label: "Deskripsi",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "encryptedId",
      label: "AKSI",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup size='small'>
              <Button
                component="label"
                variant="contained"
                color="primary"
                onClick={() => {
                  window.location.href = "/assesment/" + value;
                }}
                startIcon={<EditIcon />}>
                Edit
              </Button>
            </ButtonGroup>
          )
        }
      }
    },
  ];

  // get data
  const getData = async () => {
    setStateTable({ isLoading: true });
    const res = await listAssesment();
    setStateTable({ isLoading: false, data: res.data, count: res.recordsFiltered });
  };

  useEffect(() => {
    getData();
  }, [])

  const changePage = async (page) => {
    setStateTable({
      isLoading: true
    });
    const res = await listAssesment(page);
    setStateTable({ isLoading: false, data: res.data, count: res.recordsFiltered });
  }

  const options = {
    headerStyle: {
      textAlign: "center",
    },
    responsive: "standard",
    // filterType: 'checkbox',
    selectableRows: 'none',
    filters: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    confirmFilters: true,

    serverSide: true,

    downloadOptions: { filename: "data_dosen.csv", separator: "," },
    //count, // Use total number of items
    // count: -1, // Unknown number of items
    page: stateTable.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        // case 'propsUpdate':
        case 'sort':
        case 'changePage':
        case 'changeRowsPerPage':
        case 'search':
        case 'filterChange':
          changePage(tableState);
          break;
      }
    },
  };


  return (
    <div style={{ height: 600, width: '100%' }}>
      <MUIDataTable
        title={
          <Typography variant="h6">
            List Assesment
            {stateTable.isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={stateTable.data}
        columns={columns}
        options={options}
      />

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        title={"Informasi!"}
        description={messageAlert}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data ini ?
            <br />
            Data yang sudah dihapus tidak dapat dikembalikan
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}
            variant="contained">
            Ya, Hapus
          </Button>
          <Button onClick={handleClose} autoFocus>Batal</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}