import React, { createRef, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Input, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from '../../../../config/api';
import { getProgramStudi } from '../../../../api/programStudiApi';
import { getToken } from "../../../../util/tokenOrganizer";

function DosenFormValidationImage({ dataDosen }) {
    // let dataDosen = props.dataDosen;

    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUploadPreview, setImageUploadPreview] = useState('');

    const getProgramStudiApi = async () => {
        let res = await getProgramStudi();
        setProgramStudiList(res)
    }

    useEffect(() => {
        getProgramStudiApi();
    }, []);


    const [programStudiList, setProgramStudiList] = useState([]);


    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            window.location.href = "/master/dosen/" + dataDosen.encryptedId;
        }
    };


    const [formData, setFormData] = useState({
        email: {
            label: 'Email',
            value: dataDosen.users?.email ?? '',
            error: false,
            errorMessage: 'You must enter a mail'
        },
        password: {
            label: 'Password',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        password_confirm: {
            label: 'Password Confirm',
            value: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nidn: {
            label: 'nidn',
            value: dataDosen.nidn ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        nama: {
            label: 'Nama',
            value: dataDosen.nama ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        alamat: {
            label: 'Alamat',
            value: dataDosen.alamat ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        jurusan_nama: {
            label: 'Jurusan',
            value: dataDosen.jurusan_nama ?? '',
            error: false,
            errorMessage: 'You must enter a name'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();
        if (imageUpload !== null){

            let formDataa = new FormData();
            formDataa.append('user_id_dosen', dataDosen?.user_id);
            formDataa.append('profile', imageUpload);
            // the image field name should be similar to your api endpoint field name
            // in my case here the field name is customFile

            axios.post(
                `${configApi.BASE_URL_API}/dosen/${dataDosen?.encryptedId}/upload-image`,
                // `${configApi.BASE_URL_API}/mahasiswa/upload`,
                formDataa,
                {
                    headers: {
                        "Authorization": "Bearer " + getToken(),
                        "Content-type": "multipart/form-data",
                    },                    
                }
            )
            .then(response => {
                setIsSuccess(true)
                setMessage(response.data.message)
                setOpen(true)
            })
            .catch(err => {
                setIsSuccess(false)
                setMessage("Error upload image : ", err.response.data.message)
                setOpen(true)
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }



    const handleChangeImage = (e) => {

        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        setImageUploadPreview(image_as_base64)
        setImageUpload(image_as_files)
    }


    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
        >
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <Input
                    type='file'
                    name='file'
                    inputProps={{
                        accept: "image/*"
                    }}
                    onChange={handleChangeImage}
                />
            </FormControl>

            <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitted}
            >
                {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                }
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </ValidatorForm>
    );
}

export default DosenFormValidationImage;