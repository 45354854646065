import * as React from 'react';
import MahasiswaTable from './MahasiswaTable';
import MahasiswaBreadCrumb from './MahasiswaBreadCrumb';

import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';

export default function MahasiswaContent() {
    
    const navigate = useNavigate();

    return (
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <div style={{ marginBottom: "20px" }}>
                <MahasiswaBreadCrumb />
                <br />
                <Button
                    className='mt-2'
                    component="label"
                    variant="contained"
                    color="success"
                    onClick={() => {
                        navigate("/master/mahasiswa/form")
                    }}
                    startIcon={<AddBoxIcon />}>
                    Tambah
                </Button>
            </div>
            <Grid item xs={12}>
                <MahasiswaTable />
            </Grid>
        </Grid>
    );
}