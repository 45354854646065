import React, { createRef, useEffect, useState } from 'react';
// import MyDropzone from './MyDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import configApi from './../../../config/api';

import { getToken } from "./../../../util/tokenOrganizer";
import { getProgramStudi } from './../../../api/programStudiApi';
import { Box } from '@mui/system';
import { listDosen } from '../../../api/dosenApi';
import { useParams } from 'react-router';
import { detailMahasiswa } from '../../../api/mahasiswa';

function BimbinganDetailForm() {


    let param = useParams();

    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [mahasiswa, setMahasiswa] = useState({});
    const [dosenList, setDosenList] = useState([]);
    const [programStudiList, setProgramStudiList] = useState([]);

    const getProgramStudiApi = async () => {
        let resDosen = await listDosen();
        setDosenList(resDosen.data)
        let res = await getProgramStudi();
        setProgramStudiList(res)
        const resMahasiswa = await detailMahasiswa(param.encryptedId);
        setMahasiswa(resMahasiswa.data)
    }

    useEffect(() => {
        getProgramStudiApi();
    }, []);


    useEffect(() => {
        if (mahasiswa?.dosenPembimbingId) {
            setFormData({
                ...formData,
                dosen_pembimbing_id: {
                    value: mahasiswa.dosenPembimbingId
                }
            })
        }
    }, [mahasiswa]);


    const handleClose = () => {
        setOpen(false);
        if (isSuccess) {
            window.location.href = "/bimbingan/" + param.encryptedId;
        }
    };

    const [formData, setFormData] = useState({
        dosen_pembimbing_id: {
            label: 'Dosen Pembimbing',
            value: '',
            error: false,
            errorMessage: 'Silahkan pilih dosen'
        },
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [submitted, setSubmitted] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formRef = createRef();


    const handleSubmit = e => {
        e.preventDefault();

        const formFields = Object.keys(formData);
        let newFormData = { ...formData }
        let formSubmit = {};
        let check_error = 0;
        for (const element of formFields) {
            const currentField = element;
            const currentValue = formData[currentField].value;
            const currentLabel = formData[currentField].label;
            if (currentValue === '') {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: true,
                        errorMessage: currentLabel + ' kosong'
                    }
                }
                check_error++;
            } else if ((currentField === 'password' || currentField === 'password_confirm')) {
                if (currentValue.length < 8) {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: true,
                            errorMessage: currentLabel + ' minimal 8 karakter'
                        },
                    }
                    check_error++;
                } else if (newFormData.password.value != newFormData.password_confirm.value) {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: true,
                            errorMessage: 'Password tidak sama'
                        },
                    }
                    check_error++;
                } else {
                    newFormData = {
                        ...newFormData,
                        [currentField]: {
                            ...newFormData[currentField],
                            error: false,
                            errorMessage: ''
                        },
                    }
                    // formSubmit.push({ [currentField]: currentValue })
                    formSubmit[currentField] = currentValue;
                }
            } else if (currentField === 'email' &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(currentValue)) {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: true,
                        errorMessage: currentLabel + ' tidak valid'
                    }
                }
                check_error++;
            } else {
                newFormData = {
                    ...newFormData,
                    [currentField]: {
                        ...newFormData[currentField],
                        error: false
                    }
                }
                formSubmit[currentField] = currentValue;
            }

        }

        setFormData(newFormData)
        if (check_error === 0) {
            axios
                .post(`${configApi.BASE_URL_API}/mahasiswa/${param.encryptedId}/dosen-pembimbing`, formSubmit, {
                    headers: {
                        Authorization: "Bearer " + getToken()
                    }
                })
                .then((response) => {
                    setIsSuccess(true)
                    setMessage(response.data.msg)
                    setOpen(true)
                }).catch((err) => {
                    setIsSuccess(false)
                    setMessage(err.response.data.message)
                    setOpen(true)
                });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value
            }
        })
    }

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >

                <div className='mt-4'>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <TextField
                            id="outlined-dosen_pembimbing_id-option"
                            error={formData.dosen_pembimbing_id.error}
                            select
                            label="Dosen Pembimbing"
                            onChange={handleChange}
                            value={formData.dosen_pembimbing_id.value}
                            name='dosen_pembimbing_id'
                            helperText={formData.dosen_pembimbing_id.error ? formData.dosen_pembimbing_id.errorMessage : 'Silahkan pilih Dosen Pembimbing'}
                        >
                            {dosenList.map((option, idx) => {
                                return (<MenuItem key={option.id} value={option.id}>
                                    {option.nidn} - {option.nama}
                                </MenuItem>)
                            })}
                        </TextField>
                    </FormControl>
                </div>

                <div className='mt-2'>

                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={submitted}
                    >
                        {
                            (submitted && 'Your form is submitted!')
                            || (!submitted && 'Submit')
                        }
                    </Button>
                </div>
            </ValidatorForm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informasi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default BimbinganDetailForm;