import React from 'react';
import MiniDrawer from '../../../components/Menu/Menu';
import StudiKasusContent from './StudiKasusContent';

function StudiKasus() {
    return (
        <MiniDrawer>
            <StudiKasusContent />
        </MiniDrawer>
    );
}

export default StudiKasus;