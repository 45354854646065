import * as React from 'react';
import {
    Backdrop,
    CircularProgress,
    Grid,
} from '@mui/material';
import AssesmentDetailBreadCrumb from './AssesmentDetailBreadCrumb';
import { useParams } from 'react-router';
import AssesmentDetailHeader from './AssesmentDetailHeader';
import { AssesmentDetailContext, AssesmentJawabanContext } from './AssesmentDetailContext';
import { detailMonitoring } from '../../../api/monitoringApi';
import AssesmentJawaban from './AssesmentJawaban';

export default function AssesmentDetailContent() {
    let param = useParams();

    let [data, setData] = React.useState({})
    let [jawaban, setJawaban] = React.useState({})
    let [isLoading, setIsLoading] = React.useState(true)

    // get data
    const getData = async () => {
        setIsLoading(true);
        const res = await detailMonitoring(param.studi_kasus, param.encryptedId);
        setData(res.data);
        setJawaban(res.assesment);
        setIsLoading(false);
    };

    const handleClose = () => {
        // setIsLoading(false)
    }

    React.useEffect(() => {
        getData();
    }, [])

    return (
        <AssesmentDetailContext.Provider value={data}>
            <AssesmentJawabanContext.Provider value={jawaban}>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div style={{ marginBottom: "20px" }}>
                        <AssesmentDetailBreadCrumb />
                    </div>
                    <Grid item xs={12}>
                        <AssesmentDetailHeader />
                        <AssesmentJawaban />
                    </Grid>
                </Grid>
            </AssesmentJawabanContext.Provider>
        </AssesmentDetailContext.Provider>
    );
}