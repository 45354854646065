import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import { Stack } from '@mui/system';
import { Edit, Star, StarHalf } from '@mui/icons-material';
import { Button } from '@mui/material';

export default function SoalListSingle({
    data
}) {
    let opsi_arr = data.assesment_opsi.map(v => `${v.key_opsi}. ${v.label_opsi}`)
    let string_opsi = opsi_arr.join(" | ");


    let string_jawaban = '';
    let jawaban = [];
    if (data.jawaban != null) {
        jawaban = data.jawaban.split(",");
        string_jawaban = jawaban.join(", ");
    }

    return (
        <>
            <ListItemButton>
                <ListItemIcon>
                    {data.priority}
                </ListItemIcon>
                <ListItemText primary={
                    <Stack>
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>{data.pertanyaan}</Typography>
                        <Typography variant='body2'>{data.deskripsi}</Typography>
                        <Typography variant='body2'>{string_opsi}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Kunci Jawaban : {string_jawaban}</Typography>
                    </Stack>
                } />
                <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        window.location.href = "/assesment/MDBlODZhODFkOGNhOWI1ZWUyMzlkYWNjZWRkMGZjMGU=/soal_assesment/edit/" + data.id;
                    }}
                    startIcon={<Edit />}>
                    Edit
                </Button>
            </ListItemButton>
        </>)
}