import React from 'react';
import MiniDrawer from './../../components/Menu/Menu';
import AssesmentContent from './AssesmentContent';

function Assesment() {
    return (
        <MiniDrawer>
            <AssesmentContent />
        </MiniDrawer>
    );
}

export default Assesment;