import React from 'react';
import MiniDrawer from '../../../../components/Menu/Menu';
import MahasiswaFormContent from './MahasiswaFormContent';

function MahasiswaForm() {
    return (
        <MiniDrawer>
            <MahasiswaFormContent />
        </MiniDrawer>
    );
}

export default MahasiswaForm;