import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Stack, ThemeProvider, createTheme } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import { listMonitoring } from '../../../api/monitoringApi';
import { ListAlt } from '@mui/icons-material';
import moment from 'moment';

export default function BasicList({
  kasus,
  kasus_title
}) {

  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [listData, setListData] = React.useState([]);
  // get data
  const getData = async () => {
    setIsLoading(true);
    const res = await listMonitoring(kasus);
    setListData(res.data?.mahasiswa_assesment_history);
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsOpen(false)
  }

  React.useEffect(() => {
    getData();
  }, [])

  if (isLoading) {
    return (
      <Stack spacing={1} >
        <Skeleton variant="rounded" width={'100%'} height={60} />
        <Skeleton variant="rectangular" width={'100%'} height={60} />
        <Skeleton variant="rectangular" width={'100%'} height={60} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
      </Stack>
    )
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Informasi"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Data Kosong
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <nav aria-label="main mailbox folders">
        <List sx={{ bgcolor: '#1976d2', color: 'white' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText disableTypography
                primary={<Typography variant="button" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{kasus_title}</Typography>} />
              <IconButton
                sx={{ color: "#fff" }}
                aria-label="detail"
                onClick={() => {
                  if (listData.length == 0) {
                    setIsOpen(true)
                  } else {
                    window.location.href = "/monitoring/" + kasus
                  }
                }}>
                <ListAlt color='white' />
              </IconButton>
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="secondary mailbox folders">
        <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
          <List>
            {listData.length == 0 && (<ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Stack>
                  <Typography variant='subtitle1'>Tidak ada data</Typography>
                </Stack>} />
              </ListItemButton>
            </ListItem>)}
            {listData.map((v) => {
              let momentTime = new Date(v.created_at).toLocaleString();
              return (
                <ListItem disablePadding key={v.id}>
                  <ListItemButton onClick={() => {
                    window.location.href = `/monitoring/${kasus}/${v.encryptedId}`
                  }}>
                    <ListItemText primary={<Stack>
                      <Typography variant='subtitle1' sx={{fontWeight: 'bold'}}>{v.mahasiswa.nama}</Typography>
                      <Typography variant='body2' sx={{color: '#7d7d7d'}}>{v.mahasiswa.nim} - {momentTime}</Typography>
                    </Stack>} />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{v.nilai}</Typography>
                  </ListItemButton>
                </ListItem>);
            })}
          </List>
        </Paper>
      </nav>
    </Box>
  );
}